<template>
  <div>
    次数<el-input-number v-model="value"></el-input-number>
    重复时间(毫秒)<el-input-number v-model="repeatTime"></el-input-number>
    <el-button type="primary" style="margin-left: 10px" @click="start">开始</el-button>
    <div v-if="err">出错请重试</div>
    <div>发送{{num}}条 耗时{{time/1000}}s</div>
    <div>开始第{{current}}次发送,本次已发送{{currentNum}}条，耗时{{currentTime}}ms</div>
  </div>
</template>

<script>
  import Mqtt from "@/util/mqtt";
  export default {
    name: "SimulationMontior",
    data() {
      return{
        value:0,
        num:0,
        time:0,
        repeatTime:0,
        timer:null,
        current:0,
        currentTime:0,
        currentNum:0,
        err:null,
      };
    },
    methods:{
      start() {
        const that = this;
        this.err=null;
        window.clearInterval(this.timer);
        this.timer=null;
        this.time=0;
        this.num=0;

        const time = new Date().getTime();
        let mqtt = new Mqtt();
        this.current =0;

        this.timer = window.setInterval(()=>{
          const currentTime = new Date().getTime();
          mqtt.connect({
            onConnect(client) {
              that.mqttClient = client;
              switch (that.current%4) {
                case 0:
                  for (let i = 0; i < that.value; i++) {
                    that.num  ++;
                    that.time=new Date().getTime()-time;
                    that.currentTime=new Date().getTime()-currentTime;
                    that.currentNum=i+1;
                    client.publish("giot/0460061055067725:1/gateway/g01", JSON.stringify({
                      "deviceNo": "0460064313366513:1",
                      "time": "2022-06-24 14:14:55",
                      "source": "ger",
                      "sourceDesc": "戈尔",
                      "mode": 1,
                      "run": 1,
                      "dir": 1,
                      "floor": 2,
                      "dsFloor": "1  ",
                      "door": 2,
                      "speed": 0.0,
                      "person": -1,
                      "faults": null,
                      "bitStatus": [0, 0, 1, 1, 0, 0, 0, 0],
                      "upCalls": null,
                      "downCalls": null,
                      "carCalls": null,
                      "dsUpCalls": null,
                      "dsDownCalls": null,
                      "dsCarCalls": null,
                    }), {qos: 1});
                  }

                  that.current++;
                  break;
                case 1:
                  for (let i = 0; i < that.value; i++) {
                    that.num  ++;
                    that.time=new Date().getTime()-time;
                    that.currentTime=new Date().getTime()-currentTime;
                    that.currentNum=i+1;
                    client.publish("giot/0460061055067725:1/gateway/g03", JSON.stringify({
                      "deviceNo": "0460064510168324:1",
                      "time": "2022-06-24 14:25:13",
                      "source": "ger",
                      "sourceDesc": "戈尔",
                      "events": ["online", "stop"],
                      "status": {
                        "deviceNo": "0460064510168324:1",
                        "time": "2022-06-24 14:25:13",
                        "source": "ger",
                        "sourceDesc": "戈尔",
                        "mode": 9,
                        "run": 1,
                        "dir": 1,
                        "floor": 1,
                        "dsFloor": "\u0000\u0000\u0000",
                        "door": 2,
                        "speed": 1.55,
                        "person": -1,
                        "faults": null,
                        "bitStatus": [0, 0, 1, 1, 0, 0, 0, 0],
                        "upCalls": null,
                        "downCalls": null,
                        "carCalls": null,
                        "dsUpCalls": null,
                        "dsDownCalls": null,
                        "dsCarCalls": null,
                      },
                    }), {qos: 1});


                  }
                  that.current++;
                  break;
                case 2:
                  for (let i = 0; i < that.value; i++) {
                    that.num ++;
                    that.time=new Date().getTime()-time;
                    that.currentTime=new Date().getTime()-currentTime;
                    that.currentNum=i+1;
                    client.publish("giot/0460061055067725:1/gateway/g04", JSON.stringify({
                      "deviceNo": "0460060028168967:1",
                      "time": "2022-06-24 14:26:43",
                      "source": "ger",
                      "sourceDesc": "戈尔",
                      "runTime": 90561,
                      "runCount": 40,
                      "ropeBendCount": null,
                      "doorOpenCount": 65951,
                      "runDistance": 92183,
                      "slightMovementCount": 0,
                    }), {qos: 1});
                  }
                  that.current++;
                  break;
                case 3:
                  for (let i = 0; i < that.value; i++) {
                    that.num += 1;
                    that.time=new Date().getTime()-time;
                    that.currentTime=new Date().getTime()-currentTime;
                    that.currentNum=i+1;
                    client.publish("giot/0460061055067725/gateway/g00", JSON.stringify({
                      "deviceNo": "0460061031174925",
                      "time": "2022-06-24 14:19:45",
                      "source": "ger",
                      "sourceDesc": "戈尔",
                      "online": true,
                      "deviceType": null,
                      "elevatorType": null,
                      "version": null,
                    }), {qos: 1});
                  }
                  that.current++;
                  break;
                default:
                  for (let i = 0; i < that.value; i++) {
                    that.num ++;
                    that.time=new Date().getTime()-time;
                    that.currentTime=new Date().getTime()-currentTime;
                    that.currentNum=i+1;
                    client.publish("giot/0460061055067725:1/gateway/g01", JSON.stringify({
                      "deviceNo": "0460064313366513:1",
                      "time": "2022-06-24 14:14:55",
                      "source": "ger",
                      "sourceDesc": "戈尔",
                      "mode": 1,
                      "run": 1,
                      "dir": 1,
                      "floor": 2,
                      "dsFloor": "1  ",
                      "door": 2,
                      "speed": 0.0,
                      "person": -1,
                      "faults": null,
                      "bitStatus": [0, 0, 1, 1, 0, 0, 0, 0],
                      "upCalls": null,
                      "downCalls": null,
                      "carCalls": null,
                      "dsUpCalls": null,
                      "dsDownCalls": null,
                      "dsCarCalls": null,
                    }), {qos: 1});


                  }
                  that.current++;
                  break;
              }
              

            },
            onSend() {

            },
            onMessage() {
              that.connectTip = "";

            },
            onError(err) {
              that.connectTip = err;
              that.err =true;
              window.clearInterval(that.timer);
              that.timer=null;
              console.log(err,203);
            },
          });
        },this.repeatTime);



      },
    },
  };
</script>

<style scoped>

</style>